import * as React from "react";

import Link from '../components/Link';
import List from '../components/List';
import Seo from '../components/Seo';

import BannerSource from '../images/banner.svg';
import BlogSource from '../images/blog.svg';
import CountrySource from '../images/country.svg';
import IntegrationSource from '../images/integration.svg';
import SearchSource from '../images/search.svg';

const MediakitPage = ({ pageContext }) => {

  return (
    <>
      <Seo
        title="Презентація сайту folklore.com.ua"
        descriptions="Пропозиції щодо реклами"
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <h1 className="typo-h1 max-w-xxl lowercase mt-4">
            Інформація для медіа партнерів
          </h1>
        </div>
      </div>
      <div className="container-lg my-12 md:my-24">
        <div className="row">
          <div className="col-7">
            <h2 className="typo-h2 mb-4">
              Мета сайту
            </h2>
            <p className="typo-body">
              Нашою метою є зібрати всі витвори словесного мистецтва української національної культури в одному місці,
              створення зручних засобів пошуку та поширення. Протягом віків український народ зібрав значну культурну
              спадщину, що передавалась від покоління до покоління. Кожне покоління в свою чергу дбало, розширювало,
              вдосконалювало та передавало далі надбання своїх предків.
            </p>
          </div>
          <div className="col-4 ml-4">
            <h4 className="typo-h4 mb-4">
              Категорії
            </h4>
            <List className="mb-4">
              <List.Item className="typo-body">
                пісні
              </List.Item>
              <List.Item className="typo-body">
                казки
              </List.Item>
              <List.Item className="typo-body">
                жарти та сміховини
              </List.Item>
              <List.Item className="typo-body">
                прислів’я та приказки
              </List.Item>
              <List.Item className="typo-body">
                скоромовки
              </List.Item>
            </List>
          </div>
        </div>
      </div>
      <div className="container-lg my-12 md:my-24">
        <div className="row">
          <div className="col-4">
            <h3 className="typo-h3 mb-4">
              Трафік щомісяця
            </h3>
            <p className="mt-8 typo-h2 text-red-500">
              3 400
            </p>
            <p className="typo-body">
              відвідувань сайту
            </p>
            <p className="mt-8 typo-h2 text-red-500">
              10.1
            </p>
            <p className="typo-body">
              середня позиція сайту по запитам
            </p>
            <p className="mt-8 typo-h2 text-red-500">
              0:56
            </p>
            <p className="typo-body">
              тривалість сеансу
            </p>
          </div>
          <div className="col-4">
            <h3 className="typo-h3 mb-4">
              Аудиторія сайту
            </h3>
            <div className="mt-8">
              <img src={CountrySource} alt="Ornament" className="w-[340px]"/>
            </div>
          </div>
          <div className="col-4">
            <h3 className="typo-h3 mb-4">
              Пошук сайту в Інтернеті
            </h3>
            <div className="mt-8">
              <img src={SearchSource} alt="Ornament" className="w-full"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg my-12 md:my-24">
        <h3 className="typo-h2 mb-4">
          Методи реклами
        </h3>
        <div className="mt-4 row">
          <div className="col-4">
            <div className="h-full border-4 border-red-500 p-4">
              <h3 className="typo-h3 mb-4">
                Банерна реклама
              </h3>
              <p>
                Розміщення банерної реклами оформлюється у стилі сайту, що буде додатковим стимулом для переходу за
                посиланням користувачів. Побажання щодо розміщення, формату та дизайну реклами будуть враховані.
                <br/>
                Приклад реклами розміщеної на сайті:
              </p>
              <div className="mt-8">
                <img src={BannerSource} alt="Ornament" className="w-full"/>
              </div>
            </div>
            <div className="mt-4 border-red-2 p-4 bg-red-500">
              <p className="typo-body text-white uppercase">
                Вартість на 3 місяці: 1500 UAH
              </p>
            </div>
          </div>
          <div className="col-4">
            <div className="h-full border-4 border-red-500 p-4">
              <h3 className="typo-h3 mb-4">
                Блог про Ваш продукт/послугу
              </h3>
              <p>
              Публікація в категорії “Блог” статті про Ваш продукт/послугу включає в себе: Обговорення основних моментів,
              які мають бути включені в статтю Узгодження, внесення правок та публікація. Ваша індивідуальна стаття
              зберігається на сайті необмежений термін.
              <br/>
              Приклад статті:
              </p>
              <div className="mt-8">
                <img src={BlogSource} alt="Ornament" className="w-full"/>
              </div>
            </div>
            <div className="mt-4 p-4 bg-red-500">
              <p className="typo-body text-white uppercase">
                Вартість 2000 UAH
              </p>
            </div>
          </div>
          <div className="col-4">
            <div className="h-full border-4 border-red-500 p-4">
              <h3 className="typo-h3 mb-4">
              Інтеграція у тексти, які планує редакція
              </h3>
              <p>
              На даний момент ми наповнюємо сайт блогами про свята та традиції. Якщо Ви хочете рекламувати книгарню чи
              магазин з українським тематичним одягом. прикрасами, в такому випадку є можливість лінкувати ключові слова.
              Стаття зберігається на сайті необмежений термін зі збереженням посилань на Ваш продукт.
              <br/>
              Приклад переліновки в блозі підсвічені червоним кольором:
              </p>
              <div className="mt-8">
                <img src={IntegrationSource} alt="Ornament" className="w-full"/>
              </div>
            </div>
            <div className="mt-4 border-red-2 p-4 bg-red-500">
              <p className="typo-body text-white uppercase">
                Вартість 700 UAH
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg py-10">
        <h1 className="typo-h2">
          З вами на зв'язку:
        </h1>
        <div className="row">
          <div className="col-3">
            <p className="typo-body mt-4">
              Лідія Басмат
            <br/>
              Редактор сайту folklore.com.ua
            </p>
          </div>
          <div className="col-4">
            <p className="typo-body mt-4">
              0933614904
            <br/>
              lida.basmat@gmail.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediakitPage;
